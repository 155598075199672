<template lang="pug">
  div
    v-row
      v-col(cols="3")
        create-cost-center(
          :default-value.sync="costCenterEdit"
        )
      v-col(cols="9")
        table-cost-center(
          v-model="costCenterEdit"
        )
</template>
<script>
export default {
  name: 'CostCenters',
  components: {
    createCostCenter: () => import('./Create'),
    tableCostCenter: () => import('./Table'),
  },
  data () {
    return {
      costCenterEdit: {},
    }
  },
  methods: {

  },
}
</script>